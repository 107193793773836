const main = async () => {
    const {default: Splide} = await import(/* webpackChunkName: "splide" */ '@splidejs/splide');

    return Splide;
}
main().then(Splide => {
    let carouselMounted;

    if (!carouselMounted) {
        // create home product image rotator
        const carousels = document.querySelectorAll('.splide-carousel');

        carousels.forEach((carousel) => {
            carouselMounted = new Splide(carousel, {
                classes: {
                    arrows: 'splide__arrows dialog__arrows',
                    prev  : 'splide__arrow dialog__prev-product',
                    next  : 'splide__arrow dialog__next-product',
                },
                keyboard: true,
                pagination: false,
                track: {
                    ariaAtomic: true,
                    ariaLive: true,
                    ariaBusy: true
                }
            });
            // mount image rotator
            carouselMounted.mount();
        })
        // return instance
        return carouselMounted;
    }
})
.catch((error) => {
    console.log(error);
    console.log(`Unable to load module: ${error}`);
});